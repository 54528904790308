import React from "react";
import HomePage from "../components/templates/HomePage/HomePage";
// import HomePage from "../components/templates/HomePage/HomePageOld";

const Index = () => {
    return (
        <>
            <HomePage />
        </>
    );
};

export async function getStaticProps() {
    return {
        props: {},
    };
}

export default Index;
